import { cn } from '../../../utils/misc'

export function OuterForm({
	headingChildren,
	subHeadingChildren,
	formChildren,
	isOnboardingForm = false,
}: {
	headingChildren: React.ReactNode
	subHeadingChildren: React.ReactNode
	formChildren: React.ReactNode
	isOnboardingForm?: boolean
}) {
	return (
		<div className="container flex flex-col justify-center">
			<div className="text-center">
				<h1 className={cn('text-h1', isOnboardingForm && 'text-h2 sm:text-h1')}>
					{headingChildren}
				</h1>
				<p className="mt-3 text-body-md text-muted-foreground">
					{subHeadingChildren}
				</p>
			</div>
			<div
				className={cn(
					'mx-auto min-w-full max-w-sm sm:min-w-[368px]',
					isOnboardingForm && 'mb-auto',
				)}
			>
				{formChildren}
			</div>
		</div>
	)
}
